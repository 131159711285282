import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {obituaryBookEntity} from "@/public_obituary/store/root";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import FlipBookThumbnails from "@/public_obituary/book/flip-book/FlipBookThumbnails";
import {classNames} from "@/components/common/Helpers";
import FlipBookNav from "@/public_obituary/book/flip-book/FlipBookNav";
import {IoClose} from "react-icons/io5";
import useThumbnailSpread from "@/public_obituary/book/flip-book/hooks/useThumbnailSpread";
import useWindowResize from "@/public_obituary/book/flip-book/hooks/useWindowResize";
import LoadingIndicator from "@/public_obituary/book/flip-book/LoadingIndicator";
import ReactPageFlipWrap from "@/public_obituary/book/flip-book/ReactPageFlipWrap";
import useElementResize from "@/public_obituary/book/flip-book/hooks/useElementResize";
import ProgressBar from "../../../components/common/ui/ProgressBar";
import moment from "moment";

export default function FlipBook({obituary, active}) {
    const [pageFlip, setPageFlip] = useState();
    const events = useRef(new EventTarget());
    const pageWidth = useRef(0);
    const [pages, setPages] = useState(Array.from(Array(1), (_, index) => index + 1));
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(null);
    const [openThumbnail, setOpenThumbnail] = useState(false);
    const [isPortrait, setIsPortrait] = useState(false);
    const [flipBookRef, setFlipBookRef] = useState();
    const dispatch = useDispatch();
    const {obituaryBookList} = useSelector(state => state.obituaryBookReducer);

    const bookInfo = !Array.isArray(obituaryBookList) ? obituaryBookList : null;

    const thumbnails = useThumbnailSpread({bookInfo});

    useEffect(() => {
        let refreshTimeout;

        if (active && !bookInfo) {
            dispatch(obituaryBookEntity.services.findAll());
        }

        if (bookInfo?.pageCount > 1) {
            setPages(Array.from(Array(bookInfo?.pageCount), (_, index) => index + 1));
            setLoading(false);
        }

        if (active && bookInfo?.isUpdating) {
            refreshTimeout = setTimeout(() => {
                dispatch(obituaryBookEntity.services.findAll());
            }, 30000);
        }

        return () => {
            clearTimeout(refreshTimeout);
        };
    }, [active, obituary.id, bookInfo]);

    const emitPageResize = useCallback(() => {
        const current = pageFlip?.getBoundsRect()?.pageWidth;
        pageWidth.current = current;
        events.current.dispatchEvent(new CustomEvent('pageResize', {detail: current}));
    }, [pageFlip]);

    useElementResize(flipBookRef, () => {
        emitPageResize();
    }, [emitPageResize]);

    useWindowResize(() => {
        emitPageResize();
    }, [emitPageResize], 50);

    const onFlipBookRef = useCallback((ref) => {
        setFlipBookRef(ref);
    }, []);

    useEffect(() => {
        let interval;
        if (bookInfo?.progressBar?.started_at && bookInfo?.progressBar?.estimated_completed_at) {
            const startSecs = moment.utc(bookInfo?.progressBar?.started_at).unix();
            const endSecs = moment.utc(bookInfo?.progressBar?.estimated_completed_at).unix();

            interval = setInterval(() => {
                const nowSecs = moment.utc().unix();
                const diff = endSecs - startSecs;
                const progress = Math.round(((nowSecs - startSecs) / diff) * 100);
                setProgress(progress <= 99 ? progress : 99);

                if (progress >= 100) {
                    dispatch(obituaryBookEntity.services.findAll());
                    clearInterval(interval);
                }
            }, 1000);
        }

        return () => interval && clearInterval(interval);
    }, [bookInfo?.progressBar]);

    return (
        <div className="flex md:bg-white rounded-lg md:shadow-md shadow-black/10 md:overflow-hidden">
            <FlipBookNav
                isPortrait={isPortrait}
                onPortraitToggle={() => {
                    setLoading(true);
                    setIsPortrait((prev) => !prev);
                    setTimeout(() => setLoading(false), 800);
                }}
                onThumbnailToggle={() => setOpenThumbnail(true)}
            />

            <div className={classNames(
                "bg-gray-300",
                openThumbnail ? 'top-0 fixed h-screen w-full pt-12 z-10'
                    : 'md:w-1/6 hidden md:block relative'
            )}>
                {openThumbnail && (
                    <div className="fixed flex top-0 left-0 bg-gray-300/80 backdrop-blur-sm w-full z-40 py-3 px-5">
                        <h2 className="flex-1 content-center text-lg text-slate-500 text-sm uppercase tracking-wide">Thumbnail
                            Navigation</h2>
                        <button
                            className="flex justify-center items-center w-9 h-9 text-sm font-semibold rounded-full bg-white text-black hover:opacity-50"
                            onClick={() => setOpenThumbnail(false)}
                        >
                            <span className="sr-only">Close</span>
                            <IoClose size={20}/>
                        </button>
                    </div>
                )}

                <FlipBookThumbnails
                    thumbnails={thumbnails}
                    pageFlip={pageFlip}
                    events={events.current}
                    onClick={() => setOpenThumbnail(false)}
                />
            </div>
            <div className={classNames(
                "flex-1 p-9 relative md:overflow-hidden md:bg-gray-200 md:border-l border-gray-300",
                openThumbnail ? 'hidden' : 'block'
            )}>
                {loading && progress === null && (
                    <div className="relative" style={{
                        paddingBottom: '65%',
                    }}>
                        <LoadingIndicator/>
                    </div>
                )}

                {loading && progress !== null && (
                    <div style={{padding: '32% 0'}}>
                        <div className="max-w-xs mt-7 m-auto">
                            <ProgressBar
                                progress={progress}
                            />
                        </div>
                        <div
                            className="flex gap-4 items-center justify-center mt-3 relative z-10 text-black fill-gray-400">
                            <p className="text-sm italic text-gray-600 leading-tight">
                                A book is being generated for the first time...
                            </p>
                        </div>
                    </div>
                )}

                <div>
                    <div
                        ref={onFlipBookRef}
                        className={classNames(
                            'flipbook w-full h-full flex items-center justify-center',
                            isPortrait ? 'portrait' : 'spread',
                            loading ? 'loading' : 'active',
                        )}
                    >
                        {pages?.length > 1 && (
                            <ReactPageFlipWrap
                                obituary={obituary}
                                setPageFlip={setPageFlip}
                                pages={pages}
                                events={events}
                                pageWidth={pageWidth}
                                isPortrait={isPortrait}
                                parentRef={flipBookRef}
                            />
                        )}
                    </div>

                    {!loading && bookInfo?.isUpdating && (
                        <div>
                            <div className="max-w-xs mt-7 m-auto">
                                <ProgressBar
                                    progress={progress}
                                />
                            </div>
                            <div
                                className="flex gap-4 items-center justify-center mt-3 relative z-10 text-black fill-gray-400">
                                <p className="text-sm italic text-gray-600 leading-tight">
                                    An updated book is being generated...
                                </p>
                            </div>

                        </div>
                    )}
                </div>

                <button
                    onClick={() => {
                        pageFlip?.flipPrev();
                    }}
                    type="button"
                    className="absolute top-1/2 -mt-5 left-2 flex justify-center shadow-gray-400 shadow-sm items-center pr-1 w-10 h-10 text-sm font-semibold rounded-full border border-transparent bg-white text-black hover:opacity-50 z-10"
                >
                    <span className="sr-only">Previous Page</span>
                    <FiChevronLeft size={24}/>
                </button>

                <button
                    onClick={() => {
                        pageFlip?.flipNext();
                    }}
                    type="button"
                    className="absolute top-1/2 -mt-5 right-2 flex justify-center shadow-gray-400 shadow-sm items-center pl-1 w-10 h-10 text-sm font-semibold rounded-full border border-transparent bg-white text-black hover:opacity-50 z-10"
                >
                    <span className="sr-only">Next Page</span>
                    <FiChevronRight size={24}/>
                </button>
            </div>
            <style dangerouslySetInnerHTML={{
                __html: `
                      .flipbook .stf__item.ready {
                        display: block !important;
                      }

                      .flipbook .stf__hardShadow,
                      .flipbook .stf__hardInnerShadow {
                         filter: blur(10px);
                      }

                      .flipbook.loading .stf__parent {
                        position: absolute !important;
                      }

                      .flipbook .stf__parent {
                        transition: none;
                        transform: translateZ(0);
                        transform-style: preserve-3d;
                        opacity: 0;
                        top: 1000px;
                      }

                      .flipbook.active .stf__parent {
                        transition: all 0.4s ease;
                        opacity: 1;
                        top: 0;
                      }

                      .flipbook.active .spread-shadow {
                        display: none;
                      }

                      .flipbook.spread .stf__parent.left {
                        transform: translateX(-25%);
                      }

                      .flipbook.spread .stf__parent.right {
                        transform: translateX(25%);
                      }

                      .flipbook.spread .stf__parent .spread-shadow {
                        display: block;
                      }

                      .flipbook.spread .page:nth-child(odd) {
                        box-shadow: 4px 2px 10px 1px #00000029;
                        border-left: none;
                      }

                      .flipbook.spread .page:nth-child(even) {
                        box-shadow: 0px 2px 10px 1px #00000029;
                        border-right: none;
                      }

                      .flipbook.active .page:first-child,
                      .flipbook.active .page:last-child {
                        box-shadow: 1px 1px 10px 0px #00000029;
                      }

                      .flipbook.active .page {
                        box-shadow: 1px 1px 4px 0px #00000029;
                        border: 1px solid #efeeee;
                      }

                      .flipbook.active .page-1 {
                        border: none;
                        border-radius: 1%;
                        border-top-right-radius: 0.5%;
                        border-bottom-right-radius: 0.5%;
                        overflow: hidden;
                      }

                      .flipbook.active .page-last {
                        border: none;
                        border-radius: 1%;
                        border-top-left-radius: 0.5%;
                        border-bottom-left-radius: 0.5%;
                        overflow: hidden;
                      }
                    `
            }}></style>
        </div>
    );
}

